import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./Payment.css";

export interface PaymentCartaoCreditoProps {
  urlPagamentoCT: string
}

export default function PaymentCartaoCredito({ urlPagamentoCT }: PaymentCartaoCreditoProps) {
  return (
    <Row className="payment" >
      <Col xs={12} sm={12} md={12} lg={12}>
        <p><FormattedMessage id="pagamento.alterarTipoPagamento.CartaoCredito.p1" /> <a href={urlPagamentoCT} target="_blank"><FormattedMessage id="pagamento.alterarTipoPagamento.CartaoCredito.p2" /></a></p>
      </Col>
    </Row>
  );
}
